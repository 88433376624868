import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  decrementWebsiteWidgetRuleCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
  websiteWidgetRuleId: PropTypes.number.isRequired,
  websiteWidgetRuleName: PropTypes.string.isRequired,
};

const WebsiteWidgetRulesDeleteButton = ({
  decrementWebsiteWidgetRuleCount,
  dispatchToasterAction,
  triggerRefresh,
  websiteWidgetRuleId,
  websiteWidgetRuleName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteWebsiteWidgetRule = async (csrfToken) => {
    try {
      setIsDeleting(true);
      /* eslint-disable sort-keys */
      await axios({
        method: 'DELETE',
        url: `/api/v2/website-widget-rules/${websiteWidgetRuleId}`,
        headers: { 'X-Csrf-Token': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        addToaster({
          id: websiteWidgetRuleId,
          text: 'Site Engager Rule was deleted!',
          type: 'success',
        }),
      );
      decrementWebsiteWidgetRuleCount?.();
      triggerRefresh();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: websiteWidgetRuleId,
          text: 'Unable to delete Site Engager Rule.',
          type: 'error',
        }),
      );
    }
    setIsDeleting(false);
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: websiteWidgetRuleId }));
    }, 2000);
  };

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          <ConfirmationModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={() => deleteWebsiteWidgetRule(csrfToken)}
            header="Delete Rule?"
            body={
              <p>
                Are you sure you want to <b>permanently</b> delete this Rule &quot;
                {websiteWidgetRuleName}&quot;?
              </p>
            }
            primaryButtonText="Delete Rule"
          />

          <Button
            icon="bin"
            title="Delete Rule"
            label="Delete Rule"
            id={`delete-website-widget-rule-${websiteWidgetRuleId}`}
            className="ufr-delete-button"
            disabled={isDeleting}
            onClick={() => setIsModalOpen(true)}
          />
        </>
      )}
    </CsrfContextConsumer>
  );
};

WebsiteWidgetRulesDeleteButton.propTypes = propTypes;

export default WebsiteWidgetRulesDeleteButton;
