import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  decrementPrivacyGroupsCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  incrementTriggerRefreshCounter: PropTypes.func.isRequired,
  privacyGroupId: PropTypes.number.isRequired,
  privacyGroupName: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const modalBody = (hubCodes, hubFunctionalities, appFunctionalities, itemCodes) => {
  const hasDeps =
    hubCodes.length + hubFunctionalities.length + appFunctionalities.length + itemCodes.length;
  if (hasDeps) {
    return (
      <>
        <p>
          Are you sure you want to <b>permanently</b> delete this Privacy Group?
          <p>This Privacy Group is being used by:</p>
        </p>
        <ul id="ufr-privacy-groups-dependencies">
          {hubFunctionalities.map((func) => (
            <li>{func.HubFunctionality.name} (hub functionality)</li>
          ))}
          {appFunctionalities.map((func) => (
            <li>{func.AppFunctionality.name} (app functionality)</li>
          ))}
          {hubCodes.map((code) => (
            <li>{code.HubCustomCodeBlock.name} (hub custom code)</li>
          ))}
          {itemCodes.map((code) => (
            <li>
              {code.ItemCustomCodeBlock.name} (item id:
              {code.ItemCustomCodeBlock.id} custom code)
            </li>
          ))}
        </ul>
        <br />
        Custom Code and Functionality using this group will no longer be controlled by any privacy
        group.
      </>
    );
  } else {
    return (
      <p>
        Are you sure you want to <b>permanently</b> delete this Privacy Group?
      </p>
    );
  }
};

const PrivacyGroupsDeleteButton = ({
  decrementPrivacyGroupsCount,
  dispatchToasterAction,
  privacyGroupId,
  triggerRefresh,
  hubId,
  privacyGroupName,
  incrementTriggerRefreshCounter,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [hubCodes, setHubCodes] = useState([]);
  const [hubFunctionalities, setHubFunctionalities] = useState([]);
  const [appFunctionalities, setAppFunctionalities] = useState([]);
  const [itemCodes, setItemCodes] = useState([]);

  const retrieveDependencies = async (csrfToken) => {
    try {
      let response = await axios({
        headers: {
          'X-Csrf-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'post',
        url: `/hubs/privacy/ajax_getPrivacyGroupDependencies/${hubId}/${privacyGroupId}`,
      });
      if (response.data.meta.status === 'success') {
        setIsModalOpen(true);
        setHubFunctionalities(response.data.response.hub_functionality);
        setAppFunctionalities(response.data.response.app_functionality);
        setHubCodes(response.data.response.hub_custom_code);
        setItemCodes(response.data.response.item_custom_code);
      }
    } catch (error) {
      setIsModalOpen(false);
      dispatchToasterAction(
        addToaster({
          id: privacyGroupId,
          text: 'Privacy Group could not load dependencies, please try again.',
          type: 'error',
        }),
      );
    }
  };

  const deletePrivacyGroup = async (csrfToken) => {
    try {
      setIsDeleting(true);
      await axios({
        headers: {
          'X-Csrf-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'DELETE',
        url: `/api/v2/hubs/${hubId}/privacy-groups/${privacyGroupId}`,
      });
      dispatchToasterAction(
        addToaster({
          id: privacyGroupId,
          text: 'Privacy Group Deleted.',
          type: 'success',
        }),
      );
      decrementPrivacyGroupsCount?.();
      triggerRefresh();
      incrementTriggerRefreshCounter();
    } catch {
      dispatchToasterAction(
        addToaster({
          id: privacyGroupId,
          text: 'Privacy Group could not be deleted, please try again.',
          type: 'error',
        }),
      );
    }
    setIsDeleting(false);
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: privacyGroupId }));
    }, 2000);
  };
  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          <ConfirmationModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={() => deletePrivacyGroup(csrfToken)}
            header={`Delete Group ${privacyGroupName}`}
            body={modalBody(hubCodes, hubFunctionalities, appFunctionalities, itemCodes)}
            primaryButtonText="Delete Group"
          />

          <Button
            icon="bin"
            title="Delete Privacy Policy Group"
            label="Delete Privacy Policy Group"
            id={`delete-privacy-group-${privacyGroupId}`}
            className="ufr-delete-button"
            disabled={isDeleting}
            onClick={() => retrieveDependencies(csrfToken)}
          />
        </>
      )}
    </CsrfContextConsumer>
  );
};

PrivacyGroupsDeleteButton.propTypes = propTypes;

export default PrivacyGroupsDeleteButton;
