import { CsrfContextConsumer } from '../../../DataProviders/CsrfProvider';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import ToggleSwitch from '../../formInputs/toggle/ToggleSwitch';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  endpoint: PropTypes.func.isRequired,
  entityId: PropTypes.number.isRequired,
  entityName: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const EmbedParameterStatusToggle = ({
  entityId,
  entityName,
  isActive,
  endpoint,
  showMessage,
  disabled,
}) => {
  const toggleEmbedParamStatus = async (csrfToken) => {
    showMessage('info', 'Saving...', entityId);

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: endpoint(entityId),
        data: { enabled: !isActive },
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */
      showMessage('success', `Embed parameter ${entityName} updated.`, entityId);
    } catch {
      showMessage(
        'error',
        `There was an error updating the embed parameter ${entityName}.`,
        entityId,
      );
    }
  };

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <ToggleSwitch
          id="enable-param"
          value={isActive}
          name="enable-param"
          submitData={() => toggleEmbedParamStatus(csrfToken)}
          disabled={disabled}
        />
      )}
    </CsrfContextConsumer>
  );
};

EmbedParameterStatusToggle.propTypes = propTypes;

export default EmbedParameterStatusToggle;
