import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  canCreateStreams: PropTypes.bool.isRequired,
  isSearchDisabled: PropTypes.bool.isRequired,
  onClickCallback: PropTypes.shape({
    func: PropTypes.string,
    namespace: PropTypes.string,
  }).isRequired,
};

const StreamsListingNoStreamsMessage = ({ isSearchDisabled }) => (
  <>
    {isSearchDisabled ? (
      <p>
        There are no Streams in this Hub yet. Click the <strong>New Stream</strong> button to add
        your first Stream.
      </p>
    ) : (
      <p>No results to display. Please try adjusting your search terms or filters.</p>
    )}
  </>
);

StreamsListingNoStreamsMessage.propTypes = propTypes;

export default StreamsListingNoStreamsMessage;
