import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  dispatchToasterAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  passphrase: PropTypes.string,
  passphraseId: PropTypes.number.isRequired,
  refreshTable: PropTypes.func.isRequired,
  streamId: PropTypes.number.isRequired,
};

const DeletePassphraseModal = ({
  dispatchToasterAction,
  handleClose,
  isModalOpen,
  passphraseId,
  passphrase,
  refreshTable,
  streamId,
}) => {
  /* eslint-disable sort-keys */
  const deletePassphrase = async (csrfToken) => {
    const passphraseToasterId = 'passphrase-toaster';

    try {
      await axios({
        method: 'DELETE',
        url: `/api/v2/streams/${streamId}/passphrases/${passphraseId}`,
        headers: { 'X-Csrf-Token': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
      });
      dispatchToasterAction(
        addToaster({
          id: passphraseToasterId,
          text: 'Deleted!',
          type: 'success',
        }),
      );
      refreshTable();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: passphraseToasterId,
          text: error?.response?.data?.errors[0]?.message,
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: passphraseToasterId }));
    }, 3000);
  };
  /* eslint-enable sort-keys */

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <ConfirmationModal
          isOpen={isModalOpen}
          handleClose={handleClose}
          handleConfirm={() => deletePassphrase(csrfToken)}
          header={`Delete Passphrase "${passphrase}"?`}
          body={
            <p>
              Are you sure you want to <b>permanently</b> delete this passphrase?
            </p>
          }
          primaryButtonText="Delete"
        />
      )}
    </CsrfContextConsumer>
  );
};

DeletePassphraseModal.propTypes = propTypes;

export default DeletePassphraseModal;
