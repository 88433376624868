import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  privacyGroupId: PropTypes.number.isRequired,
};

const PrivacyGroupsRefreshButton = ({ privacyGroupId, hubId, dispatchToasterAction }) => {
  let confirmHeader = 'Refresh Privacy Group';
  let confirmBody =
    'Are you sure you want to refresh this Privacy Group? Returning visitors will be asked for their consent again.';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  /* eslint-disable sort-keys */
  const refreshRefreshButton = async (csrfToken) => {
    try {
      setIsRefreshing(true);
      await axios({
        method: 'patch',
        url: `/api/v2/hubs/${hubId}/privacy-groups/${privacyGroupId}`,
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });

      dispatchToasterAction(
        addToaster({
          id: privacyGroupId,
          text: 'Privacy Group Version has been updated.',
          type: 'success',
        }),
      );
    } catch (err) {
      dispatchToasterAction(
        addToaster({
          id: privacyGroupId,
          text: 'Unable to Update Privacy Group.',
          type: 'error',
        }),
      );
    }
    setIsRefreshing(false);
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: privacyGroupId }));
    }, 2000);
  };
  /* eslint-enable sort-keys */

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          <ConfirmationModal
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={() => refreshRefreshButton(csrfToken)}
            header={confirmHeader}
            body={confirmBody}
            primaryButtonText="Refresh Group"
          />

          <Button
            icon="refresh"
            title="Refresh"
            label="Refresh Privacy Group"
            id={`refresh-privacy-group-${privacyGroupId}`}
            className="ufr-refresh-button"
            disabled={isRefreshing}
            onClick={() => setIsModalOpen(true)}
          />
        </>
      )}
    </CsrfContextConsumer>
  );
};

PrivacyGroupsRefreshButton.propTypes = propTypes;

export default PrivacyGroupsRefreshButton;
