import './successCtaAccordion.less';
import Accordion from '@/components/shared/accordion/Accordion';
import Checkbox from '@/components/formInputs/checkbox/Checkbox';
import PropTypes from 'prop-types';
import RadioButtons from '@/components/formInputs/radioButtons/RadioButtons';
import React, { useRef } from 'react';
import TextArea from '@/components/formInputs/textArea/TextArea';
import TextInput from '@/components/formInputs/textInput/TextInput';

const propTypes = {
  ctaId: PropTypes.string.isRequired,
  form: PropTypes.shape({
    custom_form_handler: PropTypes.number.isRequired,
    custom_form_handler_url: PropTypes.string,
    never_hide: PropTypes.number.isRequired,
    never_hide_cta_action: PropTypes.number.isRequired,
    send_event: PropTypes.number.isRequired,
    success_button_label: PropTypes.string.isRequired,
    success_button_url: PropTypes.string.isRequired,
    success_link_active: PropTypes.number.isRequired,
    success_message: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func,
  hasCustomFormHandler: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpenAccordion: PropTypes.func.isRequired,
};

const SuccessCtaAccordion = ({
  ctaId,
  form,
  handleSubmit,
  hasCustomFormHandler,
  isOpen,
  setOpenAccordion,
}) => {
  const formData = useRef(form);

  const _handleSubmit = (data, action) => {
    const { propertyName, propertyValue } = data;
    formData.current[propertyName] = propertyValue;
    if (handleSubmit) handleSubmit(formData.current, action);
  };

  const linkToContentBody = () => (
    <div className={'link-to-content-checked-content'}>
      <div className={'link-destination-text-input'}>
        <div
          type={'button'}
          className={'find-item-link'}
          href={`#manage-fields-modal-${ctaId}`}
          data-toggle="modal"
          role="button"
        >
          Find Item
        </div>
        <TextInput
          id="link-destination-link-to-content"
          name="success_button_url"
          title="Link Destination"
          placeholder={'http://www.uberflip.com'}
          value={formData.current.success_button_url}
          submitData={(newData) => _handleSubmit(newData, 'update-form-cta')}
        />
        <div className={'link-destination-text-note'}>
          <div className={'glyphicon glyphicon-info-sign'} />
          <div className={'link-destination-note'}>
            Send subscribers to premium (hidden) content in your Hub, or an external website
          </div>
        </div>
      </div>
      <div className={'link-button-label-text-area'}>
        <TextArea
          id="textarea-link-button-label"
          name="success_button_label"
          title="Link Button label"
          maxLength={24}
          rows={1}
          resize={'none'}
          value={formData.current.success_button_label}
          submitData={(newData) => _handleSubmit(newData, 'update-form-cta')}
        />
      </div>
    </div>
  );

  return (
    <Accordion
      id={'success-cta-accordion'}
      title={'Success'}
      isOpen={isOpen}
      onClick={() => setOpenAccordion('success')}
    >
      <div className={'success-cta-accordion-content'}>
        <div className={'success-cta-success-message'}>
          <TextArea
            id="textarea-success-message"
            name="success_message"
            title="Success Message"
            maxLength={40}
            rows={2}
            resize="none"
            value={formData.current.success_message}
            submitData={(newData) => _handleSubmit(newData, 'update-form-cta')}
          />
        </div>
        <div className={'success-cta-checkbox'}>
          <Checkbox
            id="success-cta-link-to-content"
            name="success_link_active"
            title="Link to Content"
            value={formData.current.success_link_active}
            submitData={(newData) =>
              _handleSubmit(
                { ...newData, propertyValue: +newData.propertyValue },
                'update-form-cta',
              )
            }
          />
          {!!formData.current.success_link_active && linkToContentBody()}
          <div className={'never-hide-cta'}>
            <Checkbox
              id={'success-cta-never-hide'}
              name="never_hide"
              title={'Never hide this CTA'}
              value={formData.current.never_hide}
              submitData={(newData) =>
                _handleSubmit(
                  { ...newData, propertyValue: +newData.propertyValue },
                  'update-form-cta',
                )
              }
            />
            <div type={'button'} className={'glyphicon glyphicon-info-sign'}></div>
          </div>
          {!!formData.current.never_hide && (
            <div className={'link-to-content-checked-content'}>
              <div className={'link-button-label-text-area'}>
                <RadioButtons
                  id="success-cta-never-hide-cta-action"
                  options={[
                    { text: 'Show primary fields', value: 0 },
                    { text: 'Show success message', value: 1 },
                  ]}
                  submitData={(val) =>
                    _handleSubmit(
                      { propertyName: 'never_hide_cta_action', propertyValue: val },
                      'update-form-cta',
                    )
                  }
                  value={formData.current.never_hide_cta_action}
                />
              </div>
            </div>
          )}
          <Checkbox
            id="success-cta-send-event"
            name="send_event"
            title="Fire G.A. Event"
            value={formData.current.send_event}
            submitData={(newData) =>
              _handleSubmit(
                { ...newData, propertyValue: +newData.propertyValue },
                'update-form-cta',
              )
            }
          />
          {hasCustomFormHandler && (
            <Checkbox
              id="success-cta-custom-form-handler"
              name="custom_form_handler"
              title="Use Pardot Form Handler"
              value={formData.current.custom_form_handler}
              submitData={(newData) =>
                _handleSubmit(
                  { ...newData, propertyValue: +newData.propertyValue },
                  'update-form-cta',
                )
              }
            />
          )}
          {!!formData.current.custom_form_handler && (
            <TextInput
              id="success-cta-custom-form-handler-input"
              name="custom_form_handler_url"
              placeholder={'Form Handler URL Here'}
              value={formData.current.custom_form_handler_url}
              submitData={(newData) => _handleSubmit(newData, 'update-form-cta')}
            />
          )}
        </div>
      </div>
    </Accordion>
  );
};

SuccessCtaAccordion.propTypes = propTypes;

export default SuccessCtaAccordion;
