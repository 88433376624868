import './ctaItem.less';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  active: PropTypes.bool,
  cta: PropTypes.shape({
    font_color: PropTypes.string,
  }),
  default_value: PropTypes.string,
  display_conditionally: PropTypes.bool,
  field_type: PropTypes.string,
  formFieldValues: PropTypes.object,
  id: PropTypes.string,
  is_conditional_parent: PropTypes.bool,
  key: PropTypes.string.isRequired,
  label: PropTypes.string,
  label_only_value: PropTypes.string,
  locked: PropTypes.bool,
  progressive_profiling: PropTypes.bool,
  required: PropTypes.bool,
  validation: PropTypes.string,
};

const CtaItemFormField = ({
  id: formFieldId,
  active,
  required,
  label,
  field_type,
  validation,
  label_only_value,
  progressive_profiling,
  locked,
  display_conditionally,
  is_conditional_parent,
  default_value,
  cta,
  formFieldValues,
}) => {
  const renderFieldInput = () => {
    if (!progressive_profiling || locked || display_conditionally || !is_conditional_parent) {
      switch (field_type) {
        case 'input':
        case 'text':
          return (
            <>
              <span
                className="cta-field-name"
                data-field-id={formFieldId}
                style={{ color: cta.font_color }}
              >
                {label}
              </span>
              <input type="text" pattern={validation} className="preview-form-field" />
            </>
          );
        case 'select':
          return (
            <>
              <span
                className="cta-field-name"
                data-field-id={formFieldId}
                style={{ color: cta.font_color }}
              >
                {label}
              </span>
              <select required={required} className="preview-form-field loaded">
                {!!formFieldValues[formFieldId] &&
                  formFieldValues[formFieldId].map(({ HubCtaFormFieldValue: fieldValue }) => (
                    <option value={fieldValue.value}>{fieldValue.label}</option>
                  ))}
              </select>
            </>
          );
        case 'checkbox':
          return (
            <>
              <input type="checkbox" className="preview-form-field" data-field-id={formFieldId} />
              <span
                className="cta-field-name"
                data-field-id={formFieldId}
                style={{ color: cta.font_color }}
              >
                {label}
              </span>
            </>
          );
        case 'hidden':
          return <input type="hidden" className="preview-form-field" data-field-id={formFieldId} />;
        case 'label-only':
          return (
            <span
              className="cta-field-name label-only-text"
              data-field-id={formFieldId}
              style={{ color: cta.font_color }}
            >
              {label_only_value}
            </span>
          );
      }
    }
  };

  return (
    <div
      className={`cta-field-section ${field_type === 'checkbox' ? 'one-line' : ''} ${
        (default_value && field_type === 'hidden' && progressive_profiling) || display_conditionally
          ? 'hide'
          : ''
      } ${locked ? 'email-field' : ''}`}
    >
      {active && renderFieldInput()}
    </div>
  );
};

CtaItemFormField.propTypes = propTypes;

export default CtaItemFormField;
