import './EnrichmentIntegrationInfoPage.less';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios/index';
import ConfirmationModal from '../modal/ConfirmationModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RemovalConfirmation from '@/components/removalConfirmation/RemovalConfirmation';
import SectionHeader from '@/components/formInputs/sectionHeader/SectionHeader';

const { nanoid } = require('nanoid/non-secure');

import ToasterStack from '../toaster/ToasterStack';
import Toggle from '../formInputs/toggle/Toggle';
import useToasterStack, { addToaster, slideDownAndRemoveToaster } from '../toaster/useToasterStack';

const { string, bool, number } = PropTypes;

const propTypes = {
  accountId: number.isRequired,
  canEdit: bool.isRequired,
  canRemove: bool.isRequired,
  importDesc: string.isRequired,
  importHeader: string.isRequired,
  importStatus: bool.isRequired,
  importTitle: string.isRequired,
  integrationId: number.isRequired,
  integrationName: string.isRequired,
  modalBody: string.isRequired,
  modalBodyLabel: string.isRequired,
  modalBodyTitle: string.isRequired,
  modalButtonText: string.isRequired,
  modalHeader: string.isRequired,
  removalDesc: string.isRequired,
  removalHeader: string.isRequired,
  removalTitle: string.isRequired,
};

const getEndpointBuilder = (accountId, integrationId) => ({
  updateEnrichmentData: () =>
    `/api/v2/accounts/${accountId}/enrichment-integrations/${integrationId}`,
});

const EnrichmentIntegrationInfoPage = ({
  accountId,
  integrationId,
  integrationName,
  canEdit,
  canRemove,
  importHeader,
  importTitle,
  importDesc,
  importStatus,
  removalHeader,
  removalTitle,
  removalDesc,
  modalHeader,
  modalBody,
  modalBodyTitle,
  modalBodyLabel,
  modalButtonText,
}) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [importStatusValue, setImportStatusValue] = useState(importStatus);

  // HACK: setting HTML from string since we want to append <a href> tag as per spec
  //       this will be revisited with DEV-18142
  const parseRemovalDesc = () => [<span dangerouslySetInnerHTML={{ __html: removalDesc }} />];
  const parseToggleDesc = () => [<span dangerouslySetInnerHTML={{ __html: importDesc }} />];

  const handleToggle = async (data, csrfToken) => {
    const { updateEnrichmentData } = getEndpointBuilder(accountId, integrationId);
    const params = {
      import: !!data.propertyValue,
      integrationCode: integrationName,
    };

    const toaster = {
      id: nanoid(), // using random id to stack toasts
    };
    // eslint-disable-next-line no-useless-catch
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: updateEnrichmentData(),
        headers: { 'X-CSRF-TOKEN': csrfToken },
        data: params,
      });
      /* eslint-enable sort-keys */
      toaster.type = 'success';
      toaster.text = params.import ? 'Data Import enabled.' : 'Data Import disabled.';
      setImportStatusValue(params.import);
    } catch (err) {
      toaster.type = 'error';
      toaster.text =
        "Looks like something went wrong and we couldn't save the settings. Please try again.";
    }

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);
  };

  const removeData = async (csrfToken) => {
    const { updateEnrichmentData } = getEndpointBuilder(accountId, integrationId);

    const toaster = {
      id: nanoid(), // using random id to stack toasts
    };

    /* eslint-disable sort-keys */
    const unToggleImportRequest = axios({
      method: 'patch',
      url: updateEnrichmentData(),
      headers: { 'X-CSRF-TOKEN': csrfToken },
      data: { import: false, integrationCode: integrationName },
    });
    /* eslint-enable sort-keys */

    const body = new FormData();
    body.append('accountId', accountId);

    /* eslint-disable sort-keys */
    const removalRequest = axios({
      method: 'post',
      url: `/enrichment/ajax_deleteData/${integrationId}`,
      headers: { 'X-CSRF-TOKEN': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
      data: body,
    });
    /* eslint-enable sort-keys */

    try {
      await axios.all([unToggleImportRequest, removalRequest]);
      toaster.type = 'success';
      toaster.text =
        'Data removal confirmed. Enrichment data will be removed within the next 4 hours';
      setImportStatusValue(false);
    } catch (err) {
      toaster.type = 'error';
      toaster.text = 'Something went wrong. Please check your settings and try again.';
    }

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 6000);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleData, setToggleData] = useState({
    propertyName: 'enable-enrichment',
    propertyValue: importStatusValue,
    validationRules: {},
  });

  const importAction = importStatusValue ? 'Disable' : 'Enable';
  const toggleModalHeader = `${importAction} ${integrationName} Data Import?`; //Enable Marketo Data Import?
  const toggleModalButtonText = `${importAction} Data Import`; //Enable Data Import.
  const toggleModalBody = importStatusValue
    ? `When you turn Data Import off, Uberflip will immediately stop importing records from ${integrationName}.`
    : `When you turn Data Import on, Uberflip will start regularly importing records from ${integrationName}.`;

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {/* eslint-disable-next-line */}
        {(csrfToken) => {
          return (
            <div className="ufr-enrichment-info-form">
              <SectionHeader text={importHeader} />
              <Toggle
                id="enable-enrichment"
                title={importTitle}
                description={parseToggleDesc()}
                value={importStatusValue}
                name="enable-enrichment"
                disabled={!canEdit}
                submitData={(data) => {
                  setIsModalOpen(true);
                  setToggleData(data);
                }}
              />

              <ConfirmationModal
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                handleConfirm={() => handleToggle(toggleData, csrfToken)}
                header={toggleModalHeader}
                body={toggleModalBody}
                primaryButtonText={toggleModalButtonText}
              />

              {canRemove ? (
                <RemovalConfirmation
                  id="ufr-remove-enrichment"
                  header={removalHeader}
                  title={removalTitle}
                  description={parseRemovalDesc()}
                  modalHeader={modalHeader}
                  modalBody={modalBody}
                  modalBodyLabel={modalBodyLabel}
                  modalBodyTitle={modalBodyTitle}
                  modalButtonText={modalButtonText}
                  handleConfirm={() => removeData(csrfToken)}
                />
              ) : undefined}
            </div>
          );
        }}
      </CsrfContextConsumer>

      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

EnrichmentIntegrationInfoPage.propTypes = propTypes;

export default EnrichmentIntegrationInfoPage;
