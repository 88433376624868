import './tagGroupListingDatatable.less';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import CreateTagGroupModal from '@/components/modal/CreateTagGroupModal/CreateTagGroupModal';
import DataTable from '@/components/datatable/DataTable';
import EditTagName from '@/components/datatables/tags/EditTagName';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import TagGroupListingButtons from './TagGroupListingButtons';
import TagsTransferListModal from '@/components/modal/TagsTransferListModal.jsx/TagsTransferListModal';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

/* eslint-disable sort-keys */
const propTypes = {
  tagGroups: PropTypes.array.isRequired,
  tagGroupListingDatatableRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  tagsListingDatatableRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

const entityName = {
  plural: 'Tag Groups',
  singular: 'Tag Group',
};

const cellClass = (key) => `ufr-dt-tag-listing-${key}-cell`;

const searchPlaceholder = () => `Search Tag Groups`;

const filterDropdowns = () => [];

const getEndpointBuilder = () => ({
  getTagGroups: () => `/api/v2/tag-groups`,
  deleteTagGroup: (id) => `/api/v2/tag-groups/${id}`,
});

const addTagGroupButton = (handleClick) => ({
  className: 'ufr-btn ufr-btn-primary',
  disabled: false,
  id: 'add-tag-group',
  onClick: handleClick,
  text: 'Add Tag Group',
});

const TAGS_PAGINATION_LIMIT = 100;
const UNGROUPED_TAG_GROUP_NAME = 'CONTENT';

const TagGroupListingDatatable = ({
  tagGroups,
  tagGroupListingDatatableRef,
  tagsListingDatatableRef,
}) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [toasterStack, dispatchToasterAction] = useToasterStack();

  const [isTagsTransferListModalOpen, setIsTagsTransferListModalOpen] = useState(false);

  const [isCreateTagGroupModalOpen, setIsCreateTagGroupModalOpen] = useState(false);

  const [loadedCsrfToken, setLoadedCsrfToken] = useState(null);

  const tagsTransferListModalData = useRef({
    ungroupedTagGroupId: 0,
    tagGroupId: 0,
    tagGroupName: '',
    loadedTransferFromData: [],
    loadedTransferToData: [],
  });

  useEffect(() => {
    if (loadedCsrfToken) {
      const getTagGroups = async (csrfToken, page) => {
        const url = `/api/v2/tag-groups?limit=${TAGS_PAGINATION_LIMIT}&page=${page}`;

        return axios({
          method: 'get',
          url,
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': csrfToken,
          },
        })
          .then((response) => response.data)
          .catch(() => []);
      };

      const findUngroupedTagGroup = (tagGroups) => {
        const ungroupedTagGroup = tagGroups.data.find(
          (tagGroup) => tagGroup.name === UNGROUPED_TAG_GROUP_NAME,
        );

        if (ungroupedTagGroup) {
          tagsTransferListModalData.current.ungroupedTagGroupId = ungroupedTagGroup.id;
          return true;
        }

        return false;
      };

      const getUngroupedTagGroup = async (csrfToken) => {
        let totalPages = 1;

        for (let page = 1; page <= totalPages; page++) {
          const tagGroups = await getTagGroups(csrfToken, page);
          if (findUngroupedTagGroup(tagGroups)) {
            return;
          }
          totalPages = tagGroups.meta.total_pages;
        }
      };

      getUngroupedTagGroup(loadedCsrfToken);
    }
  }, [loadedCsrfToken]);

  const getTagGroupTags = async (csrfToken, tagGroupId, page) => {
    const url = `/api/v2/tag-groups/${tagGroupId}/tags?limit=${TAGS_PAGINATION_LIMIT}&page=${page}`;

    return axios({
      method: 'get',
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': csrfToken,
      },
    })
      .then((response) => response.data)
      .catch((error) => {
        const toaster = {
          id: 'get-tag-group-tags-toaster',
          type: 'error',
          text: 'Error: ' + error.response.data.errors[0].message,
        };
        dispatchToasterAction(addToaster(toaster));
        setTimeout(() => {
          dispatchToasterAction(slideDownAndRemoveToaster(toaster));
        }, 2000);
        return [];
      });
  };

  const getAllPaginatedTags = async (csrfToken, tagGroupId) => {
    let allPaginatedTags = [];

    const ungroupedTags = await getTagGroupTags(csrfToken, tagGroupId, 1);
    allPaginatedTags = [...allPaginatedTags, ...ungroupedTags.data];

    const totalPages = ungroupedTags.meta.total_pages;

    for (let nextPage = 2; nextPage < totalPages + 1; nextPage++) {
      const ungroupedTags = await getTagGroupTags(csrfToken, tagGroupId, nextPage);
      allPaginatedTags = [...allPaginatedTags, ...ungroupedTags.data];
    }

    return allPaginatedTags;
  };

  const createTagGroupModalProps = {
    isOpen: isCreateTagGroupModalOpen,
    handleClose: () => {
      tagGroupListingDatatableRef.current.refreshTable();
      tagsListingDatatableRef.current.refreshTable();
      setIsCreateTagGroupModalOpen(false);
    },
    tagsTransferListModalData,
    setIsTagsTransferListModalOpen,
    dispatchToasterAction,
    getAllPaginatedTags,
  };

  const tagsTransferListModalProps = {
    isOpen: isTagsTransferListModalOpen,
    handleClose: () => {
      tagGroupListingDatatableRef.current.refreshTable();
      tagsListingDatatableRef.current.refreshTable();
      setIsTagsTransferListModalOpen(false);
    },
    tagGroupId: tagsTransferListModalData.current.tagGroupId,
    tagGroupName: tagsTransferListModalData.current.tagGroupName,
    loadedTransferFromData: tagsTransferListModalData.current.loadedTransferFromData,
    loadedTransferToData: tagsTransferListModalData.current.loadedTransferToData,
    dispatchToasterAction,
  };

  const { getTagGroups, deleteTagGroup } = getEndpointBuilder();

  if (isInitialLoad) {
    setIsInitialLoad(false);
    setIsSearchDisabled(tagGroups && tagGroups.length ? false : true);
  }

  const dropdownMenuItems = () => {
    const dropDownArray = [];
    return dropDownArray;
  };

  const getData = async (queries) => {
    queries.excludeDefault = 1;
    const {
      data: { data, meta },
    } = await axios.get(getTagGroups(), {
      params: queries,
    });

    return { data, meta };
  };

  const onClickTagsWithin = async (csrfToken, groupedTagData) => {
    tagsTransferListModalData.current.loadedTransferFromData = await getAllPaginatedTags(
      csrfToken,
      tagsTransferListModalData.current.ungroupedTagGroupId,
    );

    tagsTransferListModalData.current.loadedTransferToData = await getAllPaginatedTags(
      csrfToken,
      groupedTagData.id,
    );

    tagsTransferListModalData.current.tagGroupId = groupedTagData.id;
    tagsTransferListModalData.current.tagGroupName = groupedTagData.name;

    setIsTagsTransferListModalOpen(true);
  };

  const handleTagGroupDelete = async (csrfToken, tagGroup) => {
    const toaster = await axios({
      headers: {
        'X-CSRF-TOKEN': csrfToken,
      },
      method: 'DELETE',
      url: deleteTagGroup(tagGroup.id),
    })
      .then(() => ({
        id: `delete-tag-group-toaster-success`,
        text: 'Tag group successfully deleted',
        type: 'success',
      }))
      .catch((error) => ({
        id: `delete-tag-group-toaster-error`,
        text: `Error: ${error.response.data.errors[0].message}`,
        type: 'error',
      }));

    dispatchToasterAction(addToaster(toaster));

    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);

    if (toaster.type === 'success') {
      tagGroupListingDatatableRef.current.refreshTable();
      tagsListingDatatableRef.current.refreshTable();
    }
  };

  const columns = (csrfToken) => [
    {
      accessor: 'name',
      Cell: (cell) => (
        <EditTagName
          tagId={cell.original.id}
          tagName={cell.original.name}
          isTagGroup={true}
          key={cell.original.id}
          tagGroupListingDatatableRef={tagGroupListingDatatableRef}
          tagsListingDatatableRef={tagsListingDatatableRef}
        />
      ),
      Header: 'Tag Group Names',
      id: 'name',
    },
    {
      accessor: 'tags_count',
      Cell: (cell) => (
        <a onClick={() => onClickTagsWithin(csrfToken, cell.original)}>
          {cell.original.tags_count}
        </a>
      ),
      className: cellClass('tag-items-count'),
      Header: 'Tags Within',
      id: 'tags_count',
    },
    {
      accessor: 'modified_at',
      Cell: (cell) => <>{getHumanReadableDate(cell.original.modified_at)}</>,
      Header: 'Last Used',
      id: 'modified_at',
    },
    {
      accessor: 'controls',
      Cell: (cell) => (
        <TagGroupListingButtons
          tagGroup={cell.original}
          triggerDelete={() => handleTagGroupDelete(csrfToken, cell.original)}
        />
      ),
      className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
      minWidth: 130,
      sortable: false,
    },
  ];

  return (
    <CsrfProvider>
      <CsrfContextConsumer>
        {(csrfToken) => {
          setLoadedCsrfToken(csrfToken);
          return (
            <DataTable
              ref={tagGroupListingDatatableRef}
              id="tag-tagGroups"
              useStateHandling
              actionButtons={[addTagGroupButton(() => setIsCreateTagGroupModalOpen(true))]}
              entityName={entityName}
              filterDropdowns={filterDropdowns()}
              searchPlaceholder={searchPlaceholder()}
              columns={columns(csrfToken)}
              getData={getData}
              initialSort={{ desc: true, id: 'id' }}
              showLoadingState
              noSearchResultsMessage={
                <p>
                  {isSearchDisabled
                    ? 'There are no tag groups in this Hub yet.'
                    : 'No tag groups in all hubs match your search.'}
                </p>
              }
              hidePagination={isSearchDisabled}
              dropdownMenuItems={dropdownMenuItems()}
              disableSearch={isSearchDisabled}
            />
          );
        }}
      </CsrfContextConsumer>
      {isCreateTagGroupModalOpen ? <CreateTagGroupModal {...createTagGroupModalProps} /> : <></>}
      {isTagsTransferListModalOpen ? (
        <TagsTransferListModal {...tagsTransferListModalProps} />
      ) : (
        <></>
      )}
      <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
    </CsrfProvider>
  );
};

TagGroupListingDatatable.propTypes = propTypes;

export default TagGroupListingDatatable;
