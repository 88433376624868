import { addToaster, slideDownAndRemoveToaster } from '@/components/toaster/useToasterStack';
import { CsrfContextConsumer } from '@/DataProviders/CsrfProvider';
import axios from 'axios';
import NameAndDescriptionModal from '@/components/modal/NameAndDescriptionModal/NameAndDescriptionModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const propTypes = {
  data: PropTypes.exact({
    description: PropTypes.string,
    passphrase: PropTypes.string,
  }).isRequired,
  dispatchToasterAction: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  method: PropTypes.string.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
  refreshTable: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const PassphrasesModal = ({
  dispatchToasterAction,
  endpoint,
  handleClose,
  isModalOpen,
  method,
  data,
  primaryButtonText,
  refreshTable,
  successMessage,
  title,
}) => {
  const [currentData, setCurrentData] = useState(data);

  /* eslint-disable sort-keys */
  const handleSubmit = async (csrfToken) => {
    const passphraseToasterId = 'passphrase-toaster';

    const submittedData = {
      description: currentData.description ? currentData.description : '',
      passphrase: currentData.passphrase,
    };

    try {
      await axios({
        method: method,
        url: endpoint,
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
        },
        data: submittedData,
      });
      dispatchToasterAction(
        addToaster({
          id: passphraseToasterId,
          text: successMessage,
          type: 'success',
        }),
      );
      setCurrentData(data);
      refreshTable();
      handleClose();
    } catch (error) {
      dispatchToasterAction(
        addToaster({
          id: passphraseToasterId,
          text: error?.response?.data?.errors[0]?.message,
          type: 'error',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: passphraseToasterId }));
    }, 3000);
  };
  /* eslint-enable sort-keys */

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <NameAndDescriptionModal
          data={currentData}
          fieldIds={{
            descriptionFieldId: 'ufr-passphrase-description',
            nameFieldId: 'ufr-passphrase',
          }}
          fieldLabels={{ descriptionFieldLabel: 'Description', nameFieldLabel: 'Passphrase' }}
          fieldNameAttributes={{
            descriptionFieldName: 'description',
            nameFieldName: 'passphrase',
          }}
          title={title}
          description="Passphrases must be a combination of numbers and letters and between 6 and 40 characters long."
          handleSubmit={() => handleSubmit(csrfToken)}
          primaryButtonText={primaryButtonText}
          handleClose={handleClose}
          isOpen={isModalOpen}
          setData={setCurrentData}
          maxLength={200}
        />
      )}
    </CsrfContextConsumer>
  );
};

PassphrasesModal.propTypes = propTypes;

export default PassphrasesModal;
