import { CsrfContextConsumer } from '../../../../DataProviders/CsrfProvider';
import { slideDownAndRemoveToaster, updateToaster } from '@/components/toaster/useToasterStack';
import axios from 'axios';
import Button from '@/components/shared/button/Button';
import ConfirmationModal from '@/components/modal/ConfirmationModal';
import getArchiveStreamConfirmationMessage from './getArchiveStreamConfirmationMessage';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import StreamsListingStreamDependencyModal from './modals/StreamsListingStreamDependencyModal';

const propTypes = {
  decrementStreamsCount: PropTypes.func,
  dispatchToasterAction: PropTypes.func.isRequired,
  hubId: PropTypes.number.isRequired,
  streamId: PropTypes.number.isRequired,
  streamName: PropTypes.string,
  streamType: PropTypes.string.isRequired,
  triggerRefresh: PropTypes.func.isRequired,
};

const StreamsListingArchiveButton = ({
  hubId,
  streamId,
  streamType,
  streamName,
  triggerRefresh,
  decrementStreamsCount,
  dispatchToasterAction,
}) => {
  const [isArchiveStreamModalOpen, setIsArchiveStreamModalOpen] = useState(false);
  const [isStreamDependencyModalOpen, setIsStreamDependencyModalOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  /* eslint-disable sort-keys */
  const archiveStream = async (csrfToken, streamId) => {
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'delete',
        url: `/api/v2/streams/${streamId}?fbp=1`,
        headers: { 'X-CSRF-TOKEN': csrfToken },
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        updateToaster({
          id: streamId,
          text: 'Stream was archived!',
          type: 'success',
        }),
      );
      decrementStreamsCount?.();
      triggerRefresh();
    } catch (error) {
      setErrors(error.response.data.errors);
      setIsStreamDependencyModalOpen(true);
    }
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster({ id: streamId }));
    }, 2000);
  };
  /* eslint-enable sort-keys */

  return (
    <CsrfContextConsumer>
      {(csrfToken) => (
        <>
          <StreamsListingStreamDependencyModal
            hubId={hubId}
            isOpen={isStreamDependencyModalOpen}
            handleClose={() => setIsStreamDependencyModalOpen(false)}
            errors={errors}
          />

          <ConfirmationModal
            isOpen={isArchiveStreamModalOpen}
            handleClose={() => setIsArchiveStreamModalOpen(false)}
            handleConfirm={() => archiveStream(csrfToken, streamId)}
            header={`Archive Stream "${streamName}"`}
            body={getArchiveStreamConfirmationMessage(streamType)}
            primaryButtonText="Archive Stream"
          />

          <Button
            className="ufr-archive-button"
            label="archive stream"
            title="Archive Stream"
            id={`archive-stream-${streamId}`}
            icon="inbox-in"
            onClick={() => setIsArchiveStreamModalOpen(true)}
          />
        </>
      )}
    </CsrfContextConsumer>
  );
};

StreamsListingArchiveButton.propTypes = propTypes;

export default StreamsListingArchiveButton;
