import './DeleteVisitorAnalyticsDataModal.less';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios/index';
import CheckBoxModal from '@/components/modal/CheckBoxModal/CheckBoxModal';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const { nanoid } = require('nanoid/non-secure');

import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  buttonClass: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  ...CheckBoxModal.propTypes,
};

const defaultProps = {
  body: <></>,
  checkBoxTitle: '',
  description: <></>,
  handleClose: () => {},
  handleConfirm: () => {},
  isOpen: false,
};

function isUuidInputValid(uuid) {
  return uuid !== '';
}

const DeleteVisitorAnalyticsDataModal = ({ buttonText, buttonClass, accountId, ...props }) => {
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const [isOpen, setOpen] = useState(false);
  const [uuid, setUUID] = useState('');
  const [uuidErrorMessage, setUUIDErrorMessage] = useState('');

  const onSubmit = async (csrfToken) => {
    const toaster = {
      id: nanoid(), // using random id to stack toasts
    };

    const url = `/analytics/ajax_deleteVisitorData/${uuid}`;
    const formData = new FormData();
    formData.append('accountId', accountId);
    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'post',
        url,
        headers: { 'X-CSRF-TOKEN': csrfToken, 'X-Requested-With': 'XMLHttpRequest' },
        data: formData,
      });
      /* eslint-enable sort-keys */
      toaster.type = 'success';
      toaster.text = 'Deletion process has started.';
    } catch (err) {
      toaster.type = 'error';
      toaster.text = 'Error deleting Analytics Data, please try again or contact support.';
    }

    dispatchToasterAction(addToaster(toaster));
    setTimeout(() => {
      dispatchToasterAction(slideDownAndRemoveToaster(toaster));
    }, 2000);
  };

  const onDeleteButtonClick = () => {
    if (isUuidInputValid(uuid)) {
      setUUIDErrorMessage('');
      setOpen(true);
    } else {
      setUUIDErrorMessage('Please enter a Visitor UUID');
      setOpen(false);
    }
  };

  return (
    <div className="delete-visitor-analytics">
      <input
        type="text"
        className={classNames({
          'delete-visitor-analytics-uuid': true,
          'delete-visitor-analytics-uuid-input-error': !!uuidErrorMessage,
          'form-control': true,
        })}
        value={uuid}
        onChange={(e) => setUUID(e.target.value)}
      />
      <p className="delete-visitor-analytics-error-message">{uuidErrorMessage}</p>
      <button type="button" onClick={onDeleteButtonClick} className={buttonClass}>
        {buttonText}
      </button>
      <CsrfProvider>
        <CsrfContextConsumer>
          {/* eslint-disable-next-line */}
            {(csrfToken) => {
            return (
              <CheckBoxModal
                {...props}
                isOpen={isOpen}
                handleClose={() => setOpen(false)}
                handleConfirm={() => onSubmit(csrfToken)}
                checkBoxTitle={`Are you sure you want to delete all Personally Identifiable Information data for UUID "${uuid}"?`}
                description={
                  <>
                    This will <b>permanently</b> delete Visitor Personally Identifiable Information
                    data associated with this UUID. Once deleted, this data cannot be recovered. You
                    will not be able to stop or undo this action.
                  </>
                }
              />
            );
          }}
        </CsrfContextConsumer>
        <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
      </CsrfProvider>
    </div>
  );
};

DeleteVisitorAnalyticsDataModal.propTypes = propTypes;
DeleteVisitorAnalyticsDataModal.defaultProps = defaultProps;

export default DeleteVisitorAnalyticsDataModal;
