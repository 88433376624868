import './actionDropdown.less';
import handleInputReady from '@/utils/handleInputReady';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ServiceIcon from '@/components/shared/serviceIcon/ServiceIcon';

const propTypes = {
  options: PropTypes.shape({
    Uninstall: PropTypes.string,
  }),
};

const defaultProps = {
  options: {},
};

const ActionDropdown = ({ options }) => {
  const [optionVisibility, setOptionVisibility] = useState(false);

  return (
    <div>
      <div
        className="ufr-app-menu-background"
        onClick={() => {
          setOptionVisibility(!optionVisibility);
        }}
      >
        <ServiceIcon service={'verticaldots'} format="small" />
      </div>
      <div
        className="mp-drpdwn-options"
        style={optionVisibility ? { display: 'inline-block' } : { display: 'none' }}
      >
        <ul>
          {Object.entries(options).map(([optionName, optionValue]) => (
            <li
              key={optionName}
              onClick={() => {
                handleInputReady({ func: optionValue, namespace: 'CurrentPage' });
              }}
            >
              <button className="mp-drpdwn-link">{optionName}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ActionDropdown.propTypes = propTypes;
ActionDropdown.defaultProps = defaultProps;

export default ActionDropdown;
