import './passphrasesDatatable.less';
import { CsrfProvider } from '@/DataProviders/CsrfProvider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Avatar from '@/components/shared/avatar/Avatar';
import axios from 'axios';
import DataTable from '@/components/datatable/DataTable';
import DeletePassphraseModal from './modal/DeletePassphraseModal';
import getHumanReadableDate from '@/utils/getHumanReadableDate';
import PassphrasesAuthenticationEnabledToggle from './PassphrasesAuthenticationEnabledToggle';
import PassphrasesButtons from './PassphrasesButtons';
import PassphrasesModal from './modal/PassphrasesModal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack from '@/components/toaster/useToasterStack';

const propTypes = {
  hasAuthenticationUpdate: PropTypes.bool.isRequired,
  isAuthenticationEnabled: PropTypes.bool.isRequired,
  streamId: PropTypes.number.isRequired,
};

const cellClass = (key) => `ufr-dt-passphrases-${key}-cell`;
const headerClass = (key) => `ufr-dt-passphrases-${key}-header`;

const entityName = {
  plural: 'Passphrases',
  singular: 'Passphrase',
};

const newPassphraseActionButton = (setIsPassphrasesModalOpen, hasAuthenticationUpdate) => ({
  className: 'ufr-btn ufr-btn-primary',
  disabled: !hasAuthenticationUpdate,
  id: 'create-passphrase',
  onClick: () => setIsPassphrasesModalOpen(true),
  text: 'Add Passphrase',
});

const searchPlaceholder = (passphrasesCount) =>
  `Search ${passphrasesCount} ${
    passphrasesCount === 1 ? entityName.singular : entityName.plural
  } in this Stream`;

const getEndpointBuilder = (streamId) => ({
  editPassphrase: (passphraseId) => `/api/v2/streams/${streamId}/passphrases/${passphraseId}`,
  getPassphrases: () => `/api/v2/streams/${streamId}/passphrases`,
});

/* eslint-disable sort-keys,react/prop-types */
const columns = (
  hasAuthenticationUpdate,
  isSearchDisabled,
  dispatchToasterAction,
  renderEditPassphraseModal,
  renderDeletePassphraseModal,
) => [
  {
    Header: 'Passphrase',
    accessor: 'passphrase',
    id: 'passphrase',
    className: cellClass('passphrase'),
    headerClassName: headerClass('passphrase'),
    minWidth: 100,
    sortable: !isSearchDisabled,
    Cell: ({ original }) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-passphrase-tooltip" placement="top">
            {original.passphrase}
          </Tooltip>
        }
      >
        <div className="ufr-passphrase">{original.passphrase}</div>
      </OverlayTrigger>
    ),
  },
  {
    id: 'description',
    Header: 'Description',
    accessor: 'description',
    className: cellClass('description'),
    headerClassName: headerClass('description'),
    minWidth: 100,
    sortable: !isSearchDisabled,
    Cell: ({ original }) => (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="ufr-passphrase-description-tooltip" placement="top">
            {original.description}
          </Tooltip>
        }
      >
        <div className="ufr-passphrase-description">{original.description}</div>
      </OverlayTrigger>
    ),
  },
  {
    id: 'created_by',
    Header: 'Created By',
    accessor: 'created_by',
    className: cellClass('owner'),
    headerClassName: headerClass('owner'),
    minWidth: 160,
    sortable: !isSearchDisabled,
    Cell: ({ original: { owner } }) => [
      <Avatar
        key={`${owner.id}` + '-avatar'}
        firstName={owner.first_name}
        lastName={owner.last_name}
        avatarUrl={owner.avatar_url}
      />,
      <OverlayTrigger
        key={owner.id}
        placement="top"
        overlay={
          <Tooltip id="ufr-passphrase-owner-tooltip" placement="top">
            {`${owner.first_name} ${owner.last_name ? owner.last_name : ''}`}
          </Tooltip>
        }
      >
        <div className="ufr-passphrase-owner">
          {`${owner.first_name} ${owner.last_name ? owner.last_name : ''}`}
        </div>
      </OverlayTrigger>,
    ],
  },
  {
    id: 'created',
    Header: 'Created',
    accessor: 'created',
    className: cellClass('passphrase-created'),
    headerClassName: headerClass('passphrase-created'),
    minWidth: 110,
    sortable: !isSearchDisabled,
    Cell: ({ value }) => (
      <div className="ufr-passphrase-created"> {getHumanReadableDate(value)}</div>
    ),
  },
  {
    Header: '',
    accessor: 'controls',
    className: `${cellClass('buttons')} ufr-dt-buttons-cell`,
    headerClassName: headerClass('buttons'),
    sortable: false,
    Cell: (cell) =>
      hasAuthenticationUpdate ? (
        <PassphrasesButtons
          passphraseId={cell.original.id}
          hubId={cell.original.hub_id}
          streamId={cell.original.hub_collection_id}
          modalData={{
            description: cell.original.description,
            passphrase: cell.original.passphrase,
          }}
          refreshTable={cell.refresh}
          dispatchToasterAction={dispatchToasterAction}
          renderEditPassphraseModal={renderEditPassphraseModal}
          renderDeletePassphraseModal={renderDeletePassphraseModal}
        />
      ) : (
        ''
      ),
  },
];
/* eslint-enable sort-keys,react/prop-types */

const PassphrasesDatatable = ({ hasAuthenticationUpdate, isAuthenticationEnabled, streamId }) => {
  const [modalData, setModalData] = useState({});
  const [isPassphrasesModalOpen, setIsPassphrasesModalOpen] = useState(false);
  const [isEditPassphraseModalOpen, setIsEditPassphraseModalOpen] = useState(false);
  const [isDeletePassphraseModalOpen, setIsDeletePassphraseModalOpen] = useState(false);
  const [passphraseId, setPassphraseId] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [passphrasesCount, setPassphrasesCount] = useState(0);
  const [toasterStack, dispatchToasterAction] = useToasterStack();
  const { editPassphrase, getPassphrases } = getEndpointBuilder(streamId);

  const renderEditPassphraseModal = (id, modalData) => {
    setModalData(modalData);
    setPassphraseId(id);
    setIsEditPassphraseModalOpen(true);
  };

  const renderDeletePassphraseModal = (id, modalData) => {
    setModalData(modalData);
    setPassphraseId(id);
    setIsDeletePassphraseModalOpen(true);
  };

  const getPassphrasesData = async (queries) => {
    const {
      data: { data, meta },
    } = await axios.get(getPassphrases(), {
      params: queries,
    });
    if (isInitialLoad) {
      setIsInitialLoad(false);
      setIsSearchDisabled(false);
    }
    setPassphrasesCount(meta.count);
    return { data, meta };
  };

  const modals = ({ tableProps: { refresh } }) => {
    const shared = {
      refreshTable: refresh,
    };
    return [
      <PassphrasesModal
        {...shared}
        data={{}}
        dispatchToasterAction={dispatchToasterAction}
        endpoint={getPassphrases()}
        isModalOpen={isPassphrasesModalOpen}
        handleClose={() => setIsPassphrasesModalOpen(false)}
        method="post"
        primaryButtonText="Add"
        successMessage="Passphrase added!"
        title="Add Passphrase"
        key="add-passphrase-modal"
      />,
      <PassphrasesModal
        {...shared}
        data={modalData}
        dispatchToasterAction={dispatchToasterAction}
        endpoint={editPassphrase(passphraseId, streamId)}
        handleClose={() => setIsEditPassphraseModalOpen(false)}
        isModalOpen={isEditPassphraseModalOpen}
        method="patch"
        primaryButtonText="Update"
        successMessage="Passphrase updated!"
        title="Edit Passphrase"
        key={`edit-passphrase-modal-${passphraseId}`}
      />,
      <DeletePassphraseModal
        {...shared}
        passphrase={modalData.passphrase}
        dispatchToasterAction={dispatchToasterAction}
        isModalOpen={isDeletePassphraseModalOpen}
        handleClose={() => setIsDeletePassphraseModalOpen(false)}
        passphraseId={passphraseId}
        streamId={streamId}
        key={`delete-passphrase-modal-${passphraseId}`}
      />,
    ];
  };

  return (
    <div id="ufr-dt-passphrases">
      <CsrfProvider>
        <PassphrasesAuthenticationEnabledToggle
          hasAuthenticationUpdate={hasAuthenticationUpdate}
          streamId={streamId}
          isAuthenticationEnabled={isAuthenticationEnabled}
        />
        <DataTable
          id="passphrases"
          useStateHandling
          entityName={entityName}
          actionButtons={[
            newPassphraseActionButton(setIsPassphrasesModalOpen, hasAuthenticationUpdate),
          ]}
          searchPlaceholder={searchPlaceholder(passphrasesCount)}
          columns={columns(
            hasAuthenticationUpdate,
            isSearchDisabled,
            dispatchToasterAction,
            renderEditPassphraseModal,
            renderDeletePassphraseModal,
          )}
          getData={getPassphrasesData}
          initialSort={{ desc: false, id: 'passphrase' }}
          showLoadingState
          noSearchResultsMessage={
            <p>
              {isSearchDisabled
                ? 'There are no passphrases in this stream yet.'
                : 'No passphrases in this stream match your search.'}
            </p>
          }
          hidePagination={isSearchDisabled}
          disableSearch={isSearchDisabled}
          Modals={modals}
          key="passphrases-datatable"
        />
        <ToasterStack toasters={toasterStack} dispatchToasterAction={dispatchToasterAction} />
      </CsrfProvider>
    </div>
  );
};

PassphrasesDatatable.propTypes = propTypes;

export default PassphrasesDatatable;
