import './modalFooter.less';
import PropTypes from 'prop-types';
import React from 'react';

const { nanoid } = require('nanoid/non-secure');

const ModalFooter = ({ Buttons, children, isLoading }) => {
  const className = 'ufr-modal-footer';

  if (isLoading) {
    return <footer className={className} />;
  }

  return (
    <footer className={className}>
      {children}
      {Buttons.map((Button) => (
        <Button key={nanoid()} />
      ))}
    </footer>
  );
};

ModalFooter.defaultProps = {
  Buttons: [],
  children: '',
  isLoading: false,
};

ModalFooter.propTypes = {
  Buttons: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.func])),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool])),
  ]),
  isLoading: PropTypes.bool,
};

export default ModalFooter;
