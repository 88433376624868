import './inputContainer.less';
import classNames from 'classnames';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import Label from './Label/Label';
import PropTypes from 'prop-types';
import React from 'react';

const { nanoid } = require('nanoid/non-secure');

const InputContainer = ({ id, title, description, error, className, disabled, children }) => {
  const classes = ['ufr-input-container', disabled && 'ufr-input-container-disabled'];

  // must match labelledby
  const descriptionId = `${id}-input-description`;

  return (
    <div
      id={`${id}-ufr-input-container`}
      className={classNames(classes, className)}
      tabIndex={disabled ? '-1' : undefined}
    >
      {title && <Label htmlFor={id}>{title}</Label>}
      {typeof description === 'string' ? (
        <p id={descriptionId} className="react-input-description">
          {description}
        </p>
      ) : (
        description.map((paragraph, i) => (
          <p id={`${descriptionId}-${i}`} className="react-input-description" key={nanoid()}>
            {' '}
            {paragraph}{' '}
          </p>
        ))
      )}

      {React.Children.map(
        children,
        (Child) =>
          Child &&
          React.cloneElement(Child, {
            labelExpectsId: id,
          }),
      )}

      {error && <ErrorMessage message={error} />}
    </div>
  );
};

InputContainer.defaultProps = {
  className: '',
  description: '',
  disabled: false,
  error: '',
  title: '',
};

InputContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  ]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default InputContainer;
