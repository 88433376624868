import './quickLinks.less';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const { nanoid } = require('nanoid/non-secure');

const propTypes = {
  currentlySelectedDates: PropTypes.shape({
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment().constructor)]),
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment().constructor)]),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  quickLinkData: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      preposition: PropTypes.oneOf(['this', 'last', 'next', 'all', 'today', 'yesterday']),
      unit: PropTypes.oneOf(['days', 'day', 'weeks', 'week', 'months', 'month', 'years', 'year']),
    }),
  ).isRequired,
};

class QuickLinks extends Component {
  /**
   * Calculate the desired date range based on a particular
   * quick link's configuration (eg. next year, next 5 days, last month, etc)
   * and return the new dates
   *
   */
  calculateDates = ({ preposition, unit, number }) => {
    const startDate = moment();
    const endDate = moment();

    // From the start of the current day to the end of it
    if (preposition === 'today') {
      startDate.startOf('day');
      endDate.endOf('day');
      return { endDate, startDate };
    }

    // From the start of the preceeding day to the end of it
    if (preposition === 'yesterday') {
      startDate.subtract(1, 'day').startOf('day');
      endDate.subtract(1, 'day').endOf('day');
      return { endDate, startDate };
    }

    // Ex. Next N Ys (Next 5 months, Next 2 days, etc...)
    if (preposition === 'next' && number) {
      startDate.startOf('day');
      endDate.add(number, unit).endOf('day');
      return { endDate, startDate };
    }

    // Ex. Last N Ys (Last 5 months, Last 2 days, etc...)
    if (preposition === 'last' && number) {
      startDate.subtract(number, unit).startOf('day');
      endDate.subtract(1, 'day').endOf('day');
      return { endDate, startDate };
    }

    /**
     * Ex. Next Y (Next Month, Next year, Next day, etc)
     * meaning from the start to the end of
     * the preceeding unit of time (dec 1st to dec 31st if clicked in Nov)
     */
    if (preposition === 'next' && !number) {
      startDate.add(1, unit).startOf(unit);
      endDate.add(1, unit).endOf(unit);
      return { endDate, startDate };
    }

    /**
     * Ex. Last Y (Next Month, Next year, Next day, etc)
     * meaning from the start to the end of
     * the preceeding unit of time (dec 1st to dec 31st if clicked in Jan)
     */
    if (preposition === 'last' && !number) {
      startDate.subtract(1, unit).startOf(unit);
      endDate.subtract(1, unit).endOf(unit);
      return { endDate, startDate };
    }

    /**
     * EX. This Y (This month, this year, etc)
     * From the start of the current day/month/year/week to the end of it
     */
    if (preposition === 'this') {
      startDate.startOf(unit);
      endDate.endOf(unit);
      return { endDate, startDate };
    }

    throw new TypeError(
      `Invalid arguments supplied to quick links, one of ${preposition}, ${unit}, or ${number} is invalid`,
    );
  };

  handleClick = (quickLinkConfig) => {
    const newDates = this.calculateDates(quickLinkConfig);
    const handleSubmitFunc = this.props.handleSubmit ? this.props.handleSubmit : undefined;

    if (this.props.onClick) {
      this.props.onClick(newDates, handleSubmitFunc);
    }
  };

  render() {
    const { currentlySelectedDates, quickLinkData } = this.props;
    const createButtonText = ({ preposition = '', number = '', unit = '' }) =>
      `${preposition} ${number} ${unit}`;

    return (
      <div className="quick-links">
        {quickLinkData.map((quickLink) => {
          const quickLinkDates = this.calculateDates(quickLink);
          const datesMatch =
            quickLinkDates.startDate.isSame(currentlySelectedDates.startDate, 'day') &&
            quickLinkDates.endDate.isSame(currentlySelectedDates.endDate, 'day');
          const classes = classNames([datesMatch && 'quick-links-selected']);
          return (
            <button
              type="button"
              className={classNames(classes)}
              key={`quick-link-${nanoid()}`}
              onClick={() => this.handleClick(quickLink)}
            >
              {createButtonText(quickLink)}
            </button>
          );
        })}
      </div>
    );
  }
}

QuickLinks.propTypes = propTypes;

export default QuickLinks;
