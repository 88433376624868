import './itemPageHeader.less';
import { CsrfContextConsumer, CsrfProvider } from '@/DataProviders/CsrfProvider';
import axios from 'axios/index';
import Breadcrumbs from './Breadcrumbs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ToasterStack from '@/components/toaster/ToasterStack';
import useToasterStack, {
  addToaster,
  slideDownAndRemoveToaster,
  updateToaster,
} from '@/components/toaster/useToasterStack';

const propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  canUpdateItem: PropTypes.bool.isRequired,
  itemId: PropTypes.number.isRequired,
  itemType: PropTypes.string.isRequired,
  published: PropTypes.bool,
  title: PropTypes.shape({
    externalName: PropTypes.string.isRequired,
    internalName: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {
  breadcrumbs: [],
};

const ItemPageHeader = ({ itemId, itemType, breadcrumbs, title, published }) => {
  const [cachedInternalNameValue, setCachedInternalNameValue] = useState(title.internalName);
  const [cachedTitleValue, setCachedTitleValue] = useState(title.externalName);
  const [currentInternalNameValue, setCurrentInternalNameValue] = useState(title.internalName);
  const [currentTitleValue, setCurrentTitleValue] = useState(title.externalName);
  const [toasters, dispatchToasterAction] = useToasterStack();

  let showHideMenu;
  const initializeMenu = () => {
    showHideMenu = document.querySelector('#show-hide-menu-item');

    if (showHideMenu) {
      showHideMenu.style.display = 'none';
    }
  };

  useEffect(() => {
    if (!published) {
      document.addEventListener('DOMContentLoaded', initializeMenu);
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', initializeMenu);
    };
  }, []);

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'name':
        setCurrentInternalNameValue(event.target.value);
        break;
      case 'title':
        setCurrentTitleValue(event.target.value);
        break;
    }
  };

  const handleSubmit = (type, csrfToken) => async (event) => {
    if (!event.target.value) {
      switch (type) {
        case 'name':
          setCurrentInternalNameValue(cachedInternalNameValue);
          break;
        case 'title':
          setCurrentTitleValue(cachedTitleValue);
      }

      return;
    }

    dispatchToasterAction(
      addToaster({
        id: itemId,
        text: 'Saving...',
        type: 'info',
      }),
    );

    const data = {};
    data[type] = event.target.value;
    switch (type) {
      case 'name':
        setCachedInternalNameValue(event.target.value);
        break;
      case 'title':
        setCachedTitleValue(event.target.value);
    }

    try {
      /* eslint-disable sort-keys */
      await axios({
        method: 'patch',
        url: `/api/v2/items/${itemId}`,
        data: data,
        headers: {
          'X-CSRF-TOKEN': csrfToken,
        },
      });
      /* eslint-enable sort-keys */
      dispatchToasterAction(
        updateToaster({
          id: itemId,
          text: 'Saved!',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatchToasterAction(
        updateToaster({
          id: itemId,
          text: `Error: ${error.data}`,
          type: 'failure',
        }),
      );
    }
    setTimeout(() => {
      dispatchToasterAction(
        slideDownAndRemoveToaster({
          id: itemId,
        }),
      );
    }, 2000);
  };

  const handleType = (type, csrfToken) => (value) => {
    if (value.keyCode === 13) {
      handleSubmit(type, csrfToken)(value);
    }
  };

  return (
    <CsrfProvider>
      <div className="ufr-item-page">
        <CsrfContextConsumer>
          {(csrfToken) => (
            <div id="ufr-page-header" className="ufr-item-page-header">
              {breadcrumbs.length > 0 && (
                <Breadcrumbs crumbs={breadcrumbs} currentPageName={currentTitleValue} />
              )}

              <div className="ufr-internal-item-name">
                <div className="ufr-item-info">
                  <p className="ufr-item-id">ID: {itemId}</p>
                </div>
                <div className="ufr-input-container" id="internal-name-ufr-input-container">
                  <div className="ufr-label">
                    <label className="react-input-title ufr-input-label" htmlFor="name">
                      Internal Name
                    </label>
                  </div>
                  <p className="react-input-description" id="internal-name-input-description">
                    The internal name is an alternative name given to an item for ease of management
                    and is not public facing
                  </p>
                  <div className="ufr-input-inner ufr-text-inner">
                    <div className="ufr-input-wrapper">
                      <input
                        autoFocus={false}
                        className="ufr-input-component"
                        disabled={false}
                        id="internal-name"
                        name="name"
                        onBlur={handleSubmit('name', csrfToken)}
                        onChange={handleChange}
                        onKeyDown={handleType('name', csrfToken)}
                        placeholder=""
                        readOnly={false}
                        type="text"
                        value={currentInternalNameValue}
                        maxLength={140}
                      />
                    </div>
                  </div>
                </div>

                <div className="ufr-input-container" id="title-ufr-input-container">
                  <label className="react-input-title" htmlFor="title">
                    {itemType} Title
                  </label>
                  <div className="ufr-input-inner ufr-text-inner">
                    <div className="ufr-input-wrapper">
                      <input
                        autoFocus={false}
                        className="ufr-input-component"
                        disabled={false}
                        id="title"
                        name="title"
                        onBlur={handleSubmit('title', csrfToken)}
                        onChange={handleChange}
                        onKeyDown={handleType('title', csrfToken)}
                        placeholder=""
                        readOnly={false}
                        type="text"
                        value={currentTitleValue}
                        maxLength={140}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <ToasterStack toasters={toasters} dispatchToasterAction={dispatchToasterAction} />
            </div>
          )}
        </CsrfContextConsumer>
      </div>
    </CsrfProvider>
  );
};

ItemPageHeader.propTypes = propTypes;

ItemPageHeader.defaultProps = defaultProps;

export default ItemPageHeader;
